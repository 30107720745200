<template>
    <div class="page-content page-map header-clear pb-0 pt-0" style="position:relative">	
	<div id="map"></div>
	<pre id="coordinates" class="coordinates"></pre>
	<div class="get-location-mapbox"></div>
	<div id="input-localisation"></div>
	<a href="/categories" id="act1" class="btn btn-m bg-white rounded-s scale-box text-uppercase font-500"><i class="fa fa-map-marker-alt color-white font-18"></i>
<span>Signaler</span></a>
<!-- begin wrapper-->	
<!-- end wrapper -->		
	</div>
</template>
<script>
export default {
  name: 'Signaler',
  data() {
    return{
	}
  },
  components: {
  },
  mounted() {
  },  
  methods: {
	openCategories() {
	this.$emit('opencategories', '1')
	},	
  }
}


</script>
<style>
#act1{
	/*position: absolute;
	bottom:180px;
	left:35%;*/
	position: absolute;
    top: 20%;
    left: 54%;
	box-shadow: 0 5px 30px 0 rgb(0 0 0 / 11%), 0 5px 15px 0 rgb(0 0 0 / 8%) !important;
	background:#f37300!important;
	color:#fff;
	font-weight:700;
}
#act1 span{padding-left:10px; letter-spacing:1px;}

</style>